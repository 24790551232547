.rtl{
    .MuiDrawer-paperAnchorLeft {
        left: auto;
        right: 0;
    }

    .MuiButton-startIcon{
        margin-left: 8px;
        margin-right: -4px;
    }
}