* {
    box-sizing: border-box;
}

.h-100 {
    height: 100%;
}

.no-padding-h {
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.img-center {
    display: block;
    margin-right: auto;
    margin-left: auto;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

/** Displayes **/
.d-flex {
    display: flex;

    &.align-items-center {
        align-items: center;
    }

    &.align-items-flex-start {
        align-items: flex-start;
    }

    &.justify-content-center {
        justify-content: center;
    }
}

/** E Of Displays **/

/** Links **/
.default-link, .AP-main-menu-list-item-icon-label {
    text-decoration: none;
    color: $mainWhite !important;
    width: 100%;
    display: block;

    &:visited {
        color: unset;
    }

    &.active {
        color: $mainMenuColor !important;
    }
}

.gray-link {
    color: $textGray;

    &:visited {
        color: $textGray;
    }

    &.active {
        color: $mainMenuColor;
    }
}

.primary-link {
    text-decoration: none;
    color: $textBlue;
    width: 100%;
    display: block;

    &:visited {
        color: $textBlue;
    }

    &.active {
        color: $mainMenuColor;
    }

    &:hover {
        text-decoration: none !important;
    }
}

.btn-link {
    background: transparent;
    cursor: pointer;
    border: none;
}

/** E Of Links **/

.ap-radio-group {
    margin-bottom: 0px !important;
}

.vertical-align-middle {
    vertical-align: middle;
}

.tox,
.tox-silver-sink,
.tox-tinymce-aux {
    display: none;
}