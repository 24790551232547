#root {
    height: 100%;
}

#global-loader-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.z-index {
    z-index: 99999;
}

iframe {
    display: none !important;
}

.card {
    background-color: #f5f7fe;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
    padding: 15px;
}